import {
    For,
    createEffect,
    createResource,
    createSignal,
    Show
} from "solid-js";
import { produce } from "solid-js/store";

import { FormatsSelect } from "./formatsSelect";
import { ServicesSelect } from "./servicesSelect";
import { fetchAvailableFilters } from "../../api";
import { useStoreMapContext, useTranslator } from "../../contextProviders";
import { AvailableFilters, FilterParams, Format } from "../../types";
import { buildStoreFinderOptions } from "../../utils";

export interface FormatSelect {
    value: Format;
    label: string;
    isSelected: boolean;
}

export interface ServiceSelect {
    value: string;
    label: string;
    isSelected: boolean;
}

export function FilterControls(props: { availableFilters: AvailableFilters }) {
    const { t } = useTranslator();
    const { appliedFilterStore, sidePanelContentSignal, mapOptions } =
        useStoreMapContext();
    const [appliedFilters, setAppliedFilters] = appliedFilterStore;
    const [_, setSidePanelContent] = sidePanelContentSignal;

    const closeOverlay = () => {
        setSidePanelContent(undefined);
    };

    const openingHours = [
        {
            value: "all",
            label: t.sf__filters__opening_hours_all()
        },
        {
            value: "openAwhile",
            label: t.sf__filters__opening_hours_closing_soon()
        },
        {
            value: "open",
            label: t.sf__filters__opening_hours_open()
        }
    ];

    const all = openingHours.find(
        (oh) => oh.value === appliedFilters.openingHour
    )!;
    const [openingHour, setOpeningHour] = createSignal(all);

    const initialFormats: FormatSelect[] = Object.entries(Format).map(
        ([label, value]) => {
            return {
                value,
                label,
                isSelected: appliedFilters.formats.includes(value)
            };
        }
    );

    const [formats, setFormats] =
        createSignal<typeof initialFormats>(initialFormats);

    const initialServices: () => ServiceSelect[] = () =>
        props.availableFilters.services.map((s) => ({
            value: s,
            label: s,
            isSelected: appliedFilters.services.includes(s)
        }));

    const [serviceFilter, setServiceFilter] =
        createSignal<ServiceSelect[]>(initialServices());

    const fetchFilters = async (formats: FormatSelect[]) => {
        const selectedFormats = formats
            .filter((f) => f.isSelected)
            .map((f) => f.value);

        const newMapOptions: FilterParams = {
            ...mapOptions,
            formats: selectedFormats.join(",")
        };

        const { urlParams } = buildStoreFinderOptions(newMapOptions);

        const response = await fetchAvailableFilters(urlParams);
        return response;
    };

    const [filters] = createResource(formats, fetchFilters);

    createEffect(() => {
        if (!filters()) {
            return;
        }

        const availableServices = filters()!
            .services.filter((s) => !s.startsWith("Unknown"))
            .map((s) => ({
                value: s,
                label: s,
                isSelected: appliedFilters.services.includes(s)
            }));

        setServiceFilter(availableServices);
    });

    const resetFilters = () => {
        setAppliedFilters(
            produce((af) => {
                af.openingHour = "all";
                af.formats = [];
                af.services = [];
            })
        );

        setOpeningHour(openingHours[0]);
        setFormats(
            initialFormats.map((f) => ({
                ...f,
                isSelected: false
            }))
        );

        setServiceFilter(
            initialServices().map((s) => ({
                ...s,
                isSelected: false
            }))
        );
    };

    const apply = () => {
        setSidePanelContent(undefined);

        setAppliedFilters(
            produce((af) => {
                af.openingHour = openingHour().value as
                    | "all"
                    | "openAwhile"
                    | "open";

                af.formats = formats()
                    .filter((f) => f.isSelected)
                    .map((f) => f.value);

                af.services = serviceFilter()
                    .filter((s) => s.isSelected)
                    .map((s) => s.label);
            })
        );
    };

    return (
        <div class="top-0 h-full w-full space-y-6">
            <header class="flex justify-between border-b border-divider-gray px-6 py-3">
                <div
                    class="cursor-pointer self-center font-medium text-blue-600 hover:underline"
                    onClick={closeOverlay}
                >
                    {t.sf__filters__back_button()}
                </div>
                <button
                    onClick={apply}
                    class="rounded-lg bg-cta-blue px-6 py-3 font-medium text-white"
                >
                    {t.sf__filters__save_button()}
                </button>
            </header>
            <main class="flex flex-col gap-6 px-6">
                <Show when={props.availableFilters.formats.length > 1}>
                    <FormatsSelect
                        formats={formats()}
                        setFormats={setFormats}
                    />
                </Show>

                <Show when={props.availableFilters.services.length > 1}>
                    <ServicesSelect
                        services={serviceFilter()}
                        setServices={setServiceFilter}
                    />
                </Show>

                <div>
                    <div class="mb-3">
                        {t.sf__filters__opening_hours_label()}
                    </div>
                    <ul class="flex justify-between text-sm">
                        <For each={openingHours}>
                            {(openingHourItem) => (
                                <li
                                    class="flex grow cursor-pointer justify-center border border-r-0 border-gray-200 px-4 py-2 last:rounded-r last:border-r first-of-type:rounded-l hover:bg-gray-50 has-[:checked]:bg-gray-50 has-[:checked]:text-blue-600"
                                    onClick={() =>
                                        setOpeningHour(openingHourItem)
                                    }
                                >
                                    <input
                                        type="radio"
                                        name="openingHours"
                                        class="hidden"
                                        value={openingHourItem.value}
                                        id={openingHourItem.value}
                                        checked={
                                            openingHour().value ===
                                            openingHourItem.value
                                        }
                                    />
                                    <label
                                        class="cursor-pointer self-center pl-1 text-center"
                                        for={openingHourItem.value}
                                    >
                                        {openingHourItem.label}
                                    </label>
                                </li>
                            )}
                        </For>
                    </ul>
                </div>
            </main>
            <footer class="px-6 py-3">
                <div
                    onClick={resetFilters}
                    class="cursor-pointer font-medium text-red-500 hover:underline"
                >
                    {t.sf__filters__reset_filters()}
                </div>
            </footer>
        </div>
    );
}
