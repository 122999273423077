export enum Days {
    monday = "monday",
    tuesday = "tuesday",
    wednesday = "wednesday",
    thursday = "thursday",
    friday = "friday",
    saturday = "saturday",
    sunday = "sunday"
}

interface Coordinates {
    latitude: string;
    longitude: string;
}

interface TimeRange {
    from: string;
    to: string;
}

export type OpeningHours = {
    [key in Days]: TimeRange[];
};

export type CalculatedOpeningHours = {
    date: Date;
    timeLeft: string | undefined;
    hours: TimeRange[];
    specialOpeningHourReason: string | undefined;
};

export enum Format {
    Avec = "avec",
    Backwerk = "backwerk",
    Brezelkoenig = "brezelkoenig",
    KKiosk = "kkiosk",
    Spettacolo = "spettacolo",
    PressAndBooks = "pressAndBooks",
    Superguud = "superguud",
    BackFactory = "backfactory",
    Ditsch = "ditsch"
}

export const prettyPrintFormats = new Map<string, string>([
    ["Avec", "avec"],
    ["Backwerk", "Backwerk"],
    ["Brezelkoenig", "Brezelkönig"],
    ["KKiosk", "k kiosk"],
    ["Spettacolo", "Caffé Spettacolo"],
    ["PressAndBooks", "Press & Books"],
    ["Superguud", "Superguud"],
    ["BackFactory", "BACK-FACTORY"],
    ["Ditsch", "Ditsch"]
]);

export const prettyPrintServices = new Map<string, string>([
    ["Chimpy", "Chimpy"],
    ["SwisscomEasypoint", "Swisscom Easypoint"],
    ["UpsAccessPoint", "UPS Access Point"],
    ["Pudo DropOff", "Päcklipunkt"],
    ["TooGoodToGo", "Too Good To Go"],
    ["Paysafe", "Paysafe card"],
    ["OkProducts", "Ok.-"],
    ["DPDParcel", "DPD"]
]);

export enum Language {
    De = "de",
    En = "en",
    Fr = "fr",
    It = "it"
}

interface Address {
    street: string;
    postalCode: string;
    city: string;
    country: string;
}

export interface Store {
    id: string;
    vst: string;
    name: string;
    format: Format;
    services: string[];
    address: Address;
    coordinates: Coordinates;
    calculatedOpeningHours: CalculatedOpeningHours[];
}

export interface AvailableFilters {
    formats: string[];
    services: string[];
}

export type FilterParams = {
    countryCodes?: string;
    formats?: string;
    services?: string;
    search?: string;
    language?: Language;
};

export interface StoreFinderOptions {
    boundingBox: mapboxgl.LngLatBounds;
    language: Language;
}

export interface CountryOptions {
    boundingBox: mapboxgl.LngLatBounds;
}

export type SidePanelContent = "filterControls" | Store | undefined;

export const ZOOM_DURATION = 2000;

export const FIT_BOUNDS_OPTIONS = {
    padding: 50,
    easing: (t: number) => 1 - Math.pow(1 - t, 5)
};

interface Stock {
    category: string;
    name: string;
    quantity: number;
}

export interface StoreStock {
    simCardsAvailable: boolean;
    items: [string, Stock[]][];
    timestamp: Date;
}
