import {
    SearchBoxSuggestion,
    SearchBoxSuggestionResponse
} from "@mapbox/search-js-core";
import {
    createEffect,
    createResource,
    createSignal,
    For,
    Show
} from "solid-js";
import { produce } from "solid-js/store";
import { useMapContext } from "solid-map-gl";

import {
    useSearchContext,
    useStoreMapContext,
    useTranslator
} from "../../contextProviders";
import { FIT_BOUNDS_OPTIONS, ZOOM_DURATION } from "../../types";

export function SearchBar() {
    const { t, currentLanguage } = useTranslator();
    const { appliedFilterStore, defaultBounds, mapOptions, stores } =
        useStoreMapContext();
    const [appliedFilters, setAppliedFilters] = appliedFilterStore;
    const [ctx] = useMapContext();
    const { searchSession } = useSearchContext();

    const [searchTerm, setSearchTerm] = createSignal(
        appliedFilters.search.text
    );

    const resetSearchFilter = () => {
        setAppliedFilters(
            produce((af) => {
                af.search.text = "";
                af.search.resultCoordinates = [0, 0];
            })
        );
    };

    const fetchSearchSuggestions = async (searchTerm: string) => {
        if (!searchTerm) {
            return [];
        }

        const searchOptions = {
            access_token: import.meta.env.VITE_MAPBOX_ACCESS_TOKEN,
            session_token: searchSession.sessionToken.toString(),
            q: searchTerm,
            country: mapOptions.countryCodes || "ch",
            language: currentLanguage.toLowerCase(),
            types: "region,postcode,district,place,city,locality,neighborhood,street,address,poi,block",
            limit: "5"
        };

        const queryParams = new URLSearchParams(searchOptions);
        const url = new URL(
            "https://api.mapbox.com/search/searchbox/v1/suggest"
        );
        url.search = queryParams.toString();

        const response = await fetch(url.toString());
        const resultApi =
            (await response.json()) as SearchBoxSuggestionResponse;

        return resultApi.suggestions;
    };

    const flyToSearchSuggestions = async (
        suggestions: SearchBoxSuggestion[] | undefined
    ) => {
        if (!suggestions || suggestions.length === 0) {
            return;
        }

        const results = await searchSession.retrieve(suggestions[0]);

        ctx.map.flyTo({
            center: results.features[0].geometry.coordinates,
            zoom: 12,
            duration: 2000
        });
    };

    const [suggestions] = createResource(searchTerm, fetchSearchSuggestions);
    const [showSuggestions, setShowSuggestions] = createSignal(false);

    // Actually run the initial search when we have finished loading stores and the pins are in the map.
    // The timeout gives Mapbox time to add the pins to the map, so zooming is not stuttery.
    createEffect(() => {
        const s = stores();
        if (s && s.length > 0) {
            setTimeout(() => flyToSearchSuggestions(suggestions()), 500);
        }
    });

    return (
        <div class="sm:max-w-120 flex w-full flex-col bg-white sm:w-auto sm:rounded-md sm:shadow-lg">
            <div class="flex min-h-14 flex-auto items-center gap-4 px-4 py-1">
                <input
                    class="h-full min-w-0 flex-1 focus:outline-none"
                    type="text"
                    placeholder={t.sf__search__city()}
                    value={searchTerm()}
                    onKeyUp={async (e) => {
                        if (e.key === "Enter") {
                            await flyToSearchSuggestions(suggestions());
                            setShowSuggestions(false);
                        } else {
                            if (appliedFilters.search.text) {
                                resetSearchFilter();
                            }

                            setSearchTerm(e.currentTarget.value);
                            setShowSuggestions(true);
                        }
                    }}
                />
                <Show when={!!searchTerm()}>
                    <img
                        onClick={() => {
                            setSearchTerm("");
                            resetSearchFilter();

                            ctx.map.fitBounds(defaultBounds, {
                                ...FIT_BOUNDS_OPTIONS,
                                duration: ZOOM_DURATION
                            });
                        }}
                        class="mr-2 size-full w-6 self-center hover:cursor-pointer"
                        src={`${import.meta.env.VITE_CDN_BASE_URL}images/icons/clear.svg`}
                    />
                </Show>

                <img
                    class="size-full w-6 self-center hover:cursor-pointer"
                    onClick={async () => {
                        await flyToSearchSuggestions(suggestions());
                        setShowSuggestions(false);
                    }}
                    src={`${import.meta.env.VITE_CDN_BASE_URL}images/icons/search.svg`}
                    alt="search"
                />
            </div>
            <Show when={showSuggestions()}>
                <For each={suggestions()}>
                    {(s) => {
                        const suggestionText =
                            s.name + ", " + s.place_formatted;

                        return (
                            <button
                                class="block w-full cursor-pointer truncate text-nowrap rounded-none border-t bg-gray-100 px-4 py-2 text-left last:border-b hover:bg-gray-200 md:border-b-0 md:last:rounded-b-md"
                                title={suggestionText}
                                onClick={async () => {
                                    setSearchTerm(suggestionText);
                                    await flyToSearchSuggestions([s]);
                                    setShowSuggestions(false);
                                }}
                                textContent={suggestionText}
                            />
                        );
                    }}
                </For>
            </Show>
        </div>
    );
}
