import { AvailableFilters, Store, StoreStock } from "./types";
import { filterForKnownServices } from "./utils";

export const fetchAllStores = async (urlParams: string): Promise<Store[]> => {
    const filterOutBadData = (stores: Store[]) => {
        return stores
            .filter(
                (s) =>
                    parseFloat(s.coordinates.latitude) !== 0.0 &&
                    parseFloat(s.coordinates.longitude) !== 0.0
            )
            .filter(
                (s) =>
                    parseFloat(s.coordinates.latitude) <= 90 &&
                    parseFloat(s.coordinates.latitude) >= -90
            )
            .filter(
                (s) =>
                    parseFloat(s.coordinates.longitude) <= 180 &&
                    parseFloat(s.coordinates.longitude) >= -180
            );
    };

    const response = await fetch(import.meta.env.VITE_STORES_URL + urlParams);
    const loadedStores = filterOutBadData(await response.json());

    return loadedStores;
};

export const fetchAvailableFilters = async (
    urlParams: string
): Promise<AvailableFilters> => {
    const response = await fetch(
        import.meta.env.VITE_STORES_URL + "/availablefilters" + urlParams
    );

    const filters: AvailableFilters = await response.json();

    // Filter only for the ones we have logos for
    filters.services = filterForKnownServices(filters.services);

    return filters;
};

export const fetchStoreStock = async (storeId: string): Promise<StoreStock> => {
    const response = await fetch(
        import.meta.env.VITE_STORES_URL + "/" + storeId + "/stock"
    );

    return response.ok ? response.json() : "";
};
