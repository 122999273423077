import mapboxgl from "mapbox-gl";

import { CountryOptions, FilterParams, StoreFinderOptions } from "./types";
import { parseLanguage } from "./utils";

const supportedCountries = new Map<string, CountryOptions>([
    [
        "de",
        {
            boundingBox: new mapboxgl.LngLatBounds(
                [5.98865807458, 47.3024876979],
                [15.0169958839, 54.983104153]
            )
        }
    ],
    [
        "ch",
        {
            boundingBox: new mapboxgl.LngLatBounds(
                [6.02260949059, 45.7769477403],
                [10.4427014502, 47.8308275417]
            )
        }
    ]
]);

const countriesToShow = (countryCodes?: string): CountryOptions[] => {
    if (!countryCodes) {
        const co: CountryOptions[] = [];
        supportedCountries.forEach((c) =>
            co.concat({ boundingBox: c.boundingBox })
        );

        return co;
    }

    let countriesToShow = countryCodes.toLocaleLowerCase().split(",");

    countriesToShow = countriesToShow.filter((c) => supportedCountries.has(c));

    return countriesToShow.map((c) => supportedCountries.get(c)!);
};

const calculateBoundingBox = (countries: CountryOptions[]) => {
    let boundingBox = supportedCountries.get("ch")!.boundingBox;

    if (countries.length) {
        boundingBox = countries
            .map((c) => c.boundingBox)
            .reduce((bbox, b) => bbox.extend(b), new mapboxgl.LngLatBounds());
    }

    return boundingBox;
};

const buildStoreFinderOptions = (
    filterParams?: FilterParams
): StoreFinderOptions => {
    const countriesToShosw = countriesToShow(filterParams?.countryCodes);

    return {
        boundingBox: calculateBoundingBox(countriesToShosw),
        language: parseLanguage(filterParams?.language)
    };
};

export { buildStoreFinderOptions };
