import { FeatureCollection } from "geojson";

import { useTranslator } from "./contextProviders";
import {
    FilterParams,
    Language,
    Store,
    prettyPrintFormats,
    prettyPrintServices
} from "./types";

export function storesToGeoJson(stores: Store[]): FeatureCollection {
    const geoJson: FeatureCollection = {
        type: "FeatureCollection",
        features: stores.map((store, index) => {
            return {
                type: "Feature",
                geometry: {
                    type: "Point",
                    coordinates: [
                        parseFloat(store.coordinates.longitude),
                        parseFloat(store.coordinates.latitude)
                    ]
                },
                properties: {
                    index: index,
                    marker: `${import.meta.env.VITE_CDN_BASE_URL}images/marker-${store.format}.png`,
                    ...store
                }
            };
        })
    };

    return geoJson;
}

const buildURLSearchParams = (filterParams?: FilterParams) => {
    if (filterParams == undefined) {
        return "";
    } else {
        return `?${new URLSearchParams(filterParams!).toString()}`;
    }
};

const convertToFilterParams = (
    urlSearchParams?: URLSearchParams
): FilterParams | undefined => {
    if (urlSearchParams == undefined) {
        return undefined;
    } else {
        return {
            countryCodes:
                urlSearchParams.getAll("countryCodes").join(",") || "",
            formats: urlSearchParams.getAll("formats").join(",") || "",
            services: urlSearchParams.getAll("services").join(",") || "",
            search: urlSearchParams.get("search") || "",
            language: parseLanguage(urlSearchParams.get("language") || "")
        };
    }
};

const tryLocalizeService = (service: string) => {
    const { t } = useTranslator();

    switch (service) {
        case "SmartsGasStation":
            return t.sf__service_tankstelle();
        case "UmbrellaRental":
            return t.sf__service_umbrella_rental();
        default:
            return service;
    }
};

const tryLocalizeOpeningHour = (openingHour: string) => {
    const { t } = useTranslator();

    switch (openingHour) {
        case "openAwhile":
            return t.sf__filters__opening_hours_closing_soon();
        case "open":
            return t.sf__filters__opening_hours_open();
        default:
            return openingHour;
    }
};

export const buildStoreFinderOptions = (
    filterParams?: FilterParams | URLSearchParams
) => {
    let urlParams = "";
    let parsedMapOptions: FilterParams | undefined = undefined;

    // filterParams is a URLSearchParams instance
    if (!!filterParams && "size" in filterParams && "entries" in filterParams) {
        urlParams = `?${new URLSearchParams(filterParams!).toString()}`;
        parsedMapOptions = convertToFilterParams(
            filterParams as URLSearchParams
        );
        // filterParams should be an object of the FilterParams shape
    } else {
        urlParams = buildURLSearchParams(
            filterParams as FilterParams | undefined
        );
        parsedMapOptions = filterParams as FilterParams | undefined;
    }

    return { urlParams, parsedMapOptions };
};

export const filterForKnownServices = (services: string[]) =>
    services.filter(
        (s) =>
            s === "Chimpy" ||
            s === "SwisscomEasypoint" ||
            s === "UpsAccessPoint" ||
            s === "Pudo DropOff" ||
            s === "SmartsGasStation" ||
            s === "UmbrellaRental" ||
            s === "TooGoodToGo" ||
            s === "OkProducts" ||
            s === "Paysafe" ||
            s === "24/7" ||
            s === "DitschSnackStandort" ||
            s === "DPDParcel"
    );

export const prettyPrintFormat = (format: string) => {
    return prettyPrintFormats.get(format) ?? format;
};

export const prettyPrintDropdown = (label: string) => {
    return (
        prettyPrintFormats.get(label) ??
        prettyPrintServices.get(label) ??
        tryLocalizeService(label)
    );
};

export const prettyPrintFiltersLabel = (label: string) => {
    return (
        prettyPrintFormats.get(label) ??
        prettyPrintServices.get(label) ??
        tryLocalizeOpeningHour(tryLocalizeService(label))
    );
};

export const localizeAndPrettyPrintService = (service: string) => {
    return prettyPrintServices.get(service) ?? tryLocalizeService(service);
};

export const localizeOpeningHour = (openingHour: string) => {
    return tryLocalizeOpeningHour(openingHour);
};

export const parseLanguage = (language?: string): Language => {
    if (!language) {
        return Language.De;
    }

    switch (language.toLocaleLowerCase()) {
        case "de":
            return Language.De;
        case "en":
            return Language.En;
        case "fr":
            return Language.Fr;
        case "it":
            return Language.It;
        default:
            console.warn(
                `Could not parse language code: '${language}'. Supported languages are: ${Object.entries(
                    Language
                )
                    .map(([k]) => k)
                    .join(", ")}. Defaulting to '${Language.De}'`
            );
            return Language.De;
    }
};
