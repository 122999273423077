import { Setter } from "solid-js";

import { ServiceSelect } from "./filterControls";
import { useTranslator } from "../../contextProviders";
import { localizeAndPrettyPrintService } from "../../utils";
import { MultiSelectDropdown } from "../multiSelectDropdown";

export function ServicesSelect(props: {
    services: ServiceSelect[];
    setServices: Setter<ServiceSelect[]>;
}) {
    const { t } = useTranslator();

    const servicesDisplayText = () => {
        if (props.services.filter((s) => s.isSelected).length === 0) {
            return t.sf__filters__services_none_selected();
        } else if (props.services.filter((s) => s.isSelected).length > 1) {
            const count = props.services.filter((s) => s.isSelected).length;

            return t.sf__filters__services_multiple_selected({ count });
        } else {
            const label = props.services.find((s) => s.isSelected)!.label ?? "";
            return localizeAndPrettyPrintService(label);
        }
    };

    return (
        <MultiSelectDropdown
            label={t.sf__filters__services_label()}
            displayText={servicesDisplayText()}
            options={props.services}
            onSelect={(value) => {
                const modified = props.services.map((s) => ({
                    ...s,
                    isSelected: s.value === value ? !s.isSelected : s.isSelected
                }));
                props.setServices(modified);
            }}
        />
    );
}
