import { For, Show, createResource } from "solid-js";

import { fetchStoreStock } from "../api";
import { useTranslator } from "../contextProviders";

export function Stock(props: { storeId: string }) {
    const { t, currentLanguage } = useTranslator();
    const [swisscomStock] = createResource(
        () => props.storeId,
        (storeId) => fetchStoreStock(storeId)
    );

    const getCategoryName = (category: string) => {
        switch (category) {
            case "tv":
                return t.sf__swisscom_tv();
            case "routers_and_accessories":
                return t.sf__routers_accessories();
            default:
                return category;
        }
    };

    const formatTimestamp = (timestamp: Date) => {
        const options: Intl.DateTimeFormatOptions = {
            dateStyle: "medium",
            timeStyle: "short"
        };

        return new Date(timestamp).toLocaleString(currentLanguage, options);
    };

    return (
        <div class="flex flex-col gap-2">
            <Show when={swisscomStock.loading}>
                <svg
                    viewBox="0 0 38 38"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    data-test-name="oval"
                    role="img"
                    stroke="currentColor"
                    width="38"
                    height="38"
                    class="mx-auto mt-10 w-full"
                >
                    <g fill="none" fill-rule="evenodd">
                        <g transform="translate(1 1)" stroke-width="2">
                            <circle
                                stroke-opacity=".5"
                                cx="18"
                                cy="18"
                                r="18"
                            />
                            <path d="M36 18c0-9.94-8.06-18-18-18">
                                <animateTransform
                                    attributeName="transform"
                                    type="rotate"
                                    from="0 18 18"
                                    to="360 18 18"
                                    dur="1s"
                                    repeatCount="indefinite"
                                />
                            </path>
                        </g>
                    </g>
                </svg>
            </Show>
            <Show when={!swisscomStock.loading && !!swisscomStock()}>
                <dl class="mt-4 font-bold">
                    {swisscomStock()!.simCardsAvailable
                        ? t.sf__sim_available()
                        : t.sf__sim_unavailable()}
                </dl>
                <For each={swisscomStock()!.items}>
                    {([category, articles]) => (
                        <dl class="mt-4">
                            <dt class="mb-5 font-bold">
                                {getCategoryName(category)}
                            </dt>
                            <For
                                each={articles.sort((p1, p2) =>
                                    p1.name.localeCompare(p2.name)
                                )}
                            >
                                {(article) => (
                                    <dd
                                        class={`mb-3 flex justify-between text-xs ${article.quantity <= 0 ? "text-gray-500" : ""} `}
                                    >
                                        <span class="">{article.name}</span>

                                        <span class="tabular-nums">
                                            {article.quantity < 0
                                                ? 0
                                                : article.quantity}
                                        </span>
                                    </dd>
                                )}
                            </For>
                        </dl>
                    )}
                </For>
                <p class="border-t border-divider-gray pt-3 text-xs italic">
                    {t.sf__overlay__stock_last_updated_at()}{" "}
                    {formatTimestamp(swisscomStock()!.timestamp)}
                </p>
            </Show>
        </div>
    );
}
