import { Setter } from "solid-js";

import { FormatSelect } from "./filterControls";
import { useTranslator } from "../../contextProviders";
import { prettyPrintFormat } from "../../utils";
import { MultiSelectDropdown } from "../multiSelectDropdown";

export function FormatsSelect(props: {
    formats: FormatSelect[];
    setFormats: Setter<FormatSelect[]>;
}) {
    const { t } = useTranslator();

    const formatsDisplayText = () => {
        if (props.formats.filter((f) => f.isSelected).length === 0) {
            return t.sf__filters__format_none_selected();
        } else if (props.formats.filter((f) => f.isSelected).length > 1) {
            const count = props.formats.filter((f) => f.isSelected).length;

            return t.sf__filters__format_multiple_selected({ count });
        } else {
            const label = props.formats.find((f) => f.isSelected)!.label ?? "";
            return prettyPrintFormat(label);
        }
    };

    return (
        <MultiSelectDropdown
            label={t.sf__filters__format_label()}
            displayText={formatsDisplayText()}
            options={props.formats}
            onSelect={(value) => {
                const modified = props.formats.map((f) => ({
                    ...f,
                    isSelected: f.value === value ? !f.isSelected : f.isSelected
                }));
                props.setFormats(modified);
            }}
        />
    );
}
