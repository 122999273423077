import { For, Show } from "solid-js";

import { Stock } from "./SwisscomStock";
import { useStoreMapContext, useTranslator } from "../contextProviders";
import { Language, Store } from "../types";
import {
    filterForKnownServices,
    localizeAndPrettyPrintService
} from "../utils";

interface OpeningHoursItem {
    from: string;
    to: string;
}

interface OpeningHoursItemForDay {
    isToday: boolean;
    specialOpeningHourReason: string | undefined;
    hours: OpeningHoursItem[];
}

function OpeningHours(props: { store: Store }) {
    const { t } = useTranslator();

    const openingHoursItems = (): [string, OpeningHoursItemForDay][] => {
        return props.store.calculatedOpeningHours.map((oh) => {
            const openingHours: OpeningHoursItem[] = oh.hours.map((h) => {
                return { from: h.from, to: h.to };
            });
            const isToday = oh.timeLeft !== undefined;
            const weekDay = new Date(oh.date)
                .toLocaleDateString("en-us", { weekday: "long" })
                .toLowerCase();
            return [
                t[`sf__overlay__${weekDay}` as keyof typeof t](),
                {
                    hours: openingHours,
                    specialOpeningHourReason: oh.specialOpeningHourReason,
                    isToday
                }
            ];
        });
    };

    return (
        <ul class="w-full list-none py-2">
            <For each={openingHoursItems()}>
                {([weekDay, openingHours]) => {
                    return (
                        <li
                            class={
                                "ms-4 ps-2 leading-7" +
                                (openingHours.isToday
                                    ? " list-disc font-bold"
                                    : "") +
                                (openingHours.specialOpeningHourReason !==
                                undefined
                                    ? " rounded bg-yellow-100"
                                    : "")
                            }
                        >
                            <div class="flex max-w-40 justify-between">
                                <div class="font-bold">{weekDay}</div>
                                <div class="flex flex-col">
                                    <Show
                                        when={openingHours.hours.length === 0}
                                    >
                                        <div>{t.sf__closed()}</div>
                                    </Show>
                                    <For each={openingHours.hours}>
                                        {(hours) => (
                                            <div class="tabular-nums">{`${hours.from} – ${hours.to}`}</div>
                                        )}
                                    </For>
                                    <Show
                                        when={
                                            openingHours.specialOpeningHourReason !==
                                                undefined &&
                                            !openingHours.specialOpeningHourReason
                                                .toUpperCase()
                                                .startsWith("SAP:")
                                        }
                                    >
                                        <div class="mb-2 text-xs">{`(${openingHours.specialOpeningHourReason!})`}</div>
                                    </Show>
                                </div>
                            </div>
                        </li>
                    );
                }}
            </For>
        </ul>
    );
}

export function StoreOverlay() {
    const { t, currentLanguage } = useTranslator();

    const { sidePanelContentSignal } = useStoreMapContext();
    const [sidePanelContent, setSidePanelContent] = sidePanelContentSignal;
    const selectedStore = (): Store =>
        (typeof sidePanelContent() === "object" && sidePanelContent()) as Store;
    const timeRemaining = () =>
        selectedStore().calculatedOpeningHours.find(
            (oh) => oh.timeLeft !== undefined
        )!.timeLeft;
    const isStoreOpen = () => timeRemaining() !== "closed";

    const closeOverlay = () => setSidePanelContent(undefined);

    const openTimeLeft = () => {
        const hours = parseInt(timeRemaining()!.split(":")[0], 10);
        const minutes = parseInt(timeRemaining()!.split(":")[1], 10);
        if (minutes > 1) {
            if (hours < 1) {
                return t.sf__overlay__still_open_for_minutes({
                    minutes: minutes
                });
            } else if (hours === 1) {
                return t.sf__overlay__still_open_for_hour_minutes_one({
                    minutes: minutes
                });
            } else {
                return t.sf__overlay__still_open_for_hour_minutes_other({
                    count: hours,
                    minutes: minutes
                });
            }
        } else {
            if (hours === 1) {
                return t.sf__overlay__still_open_for_hour_one();
            } else {
                return t.sf__overlay__still_open_for_hour_other({
                    count: hours
                });
            }
        }
    };

    const getServiceImageFilename = (service: string) => {
        if (service.startsWith("Pudo")) {
            switch (currentLanguage) {
                case Language.Fr:
                    return "PaeckliPunkt_fr.png";
                case Language.It:
                    return "PaeckliPunkt_it.png";
                default:
                    return "PaeckliPunkt.png";
            }
        } else if (service === "24/7") {
            return "24-7.png";
        } else {
            return service + ".png";
        }
    };

    const prettyServicesWithImages = () => {
        const services = filterForKnownServices(selectedStore().services);

        return [
            ...new Set(
                services.map((s) => [
                    localizeAndPrettyPrintService(s),
                    getServiceImageFilename(s)
                ])
            )
        ];
    };

    const ServicesImages = () => (
        <For each={prettyServicesWithImages()}>
            {([service, serviceImage]) => (
                <img
                    class="block h-auto max-h-10 w-auto p-2"
                    src={`${import.meta.env.VITE_CDN_BASE_URL}images/services/${serviceImage}`}
                    title={service}
                />
            )}
        </For>
    );

    return (
        <div class="flex h-full w-full flex-col gap-6 p-6">
            <header>
                <div
                    class="flex cursor-pointer justify-between"
                    onClick={closeOverlay}
                >
                    <img
                        class="max-h-6"
                        src={`${import.meta.env.VITE_CDN_BASE_URL}images/logo-${selectedStore().format}.png`}
                        alt="logo"
                    />

                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M18 6L6 18"
                            stroke="#6B6B6B"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M6 6L18 18"
                            stroke="#6B6B6B"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </div>
                <div class="mb-2 mt-4 font-semibold">
                    {selectedStore().name}
                </div>

                <Show when={isStoreOpen()}>
                    <div class="text-sm text-time-open">{openTimeLeft()}</div>
                </Show>
                <Show when={!isStoreOpen()}>
                    <div class="text-sm text-time-closed">
                        {t.sf__overlay__store_closed()}
                    </div>
                </Show>
            </header>
            <details class="leading-8 [&>summary>span:last-child]:open:-rotate-90">
                <summary class="flex cursor-pointer items-center justify-between">
                    <span class="text-sm font-semibold">
                        {t.sf__overlay__opening_hours()}
                    </span>
                    <span class="h-4 w-4 rotate-90 opacity-75">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                        >
                            <path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z" />
                        </svg>
                    </span>
                </summary>
                <OpeningHours store={selectedStore()} />
            </details>
            <Show when={!!selectedStore().services.length}>
                <details class="leading-8 [&>summary>span:last-child]:open:-rotate-90">
                    <summary class="flex cursor-pointer items-center justify-between">
                        <span class="text-sm font-semibold">
                            {t.sf__overlay__services()}
                        </span>
                        <span class="h-4 w-4 rotate-90 opacity-75">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                            >
                                <path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z" />
                            </svg>
                        </span>
                    </summary>
                    <ul class="flex list-none flex-wrap py-2">
                        <ServicesImages />
                    </ul>
                </details>
            </Show>
            <footer class="flex flex-col gap-2 border-t border-divider-gray pt-3">
                <address class="text-sm not-italic">
                    <div>{selectedStore().address.street}</div>
                    <div>
                        {`${selectedStore().address.postalCode} ${selectedStore().address.city}`}
                    </div>
                    <div>
                        {t[
                            `sf__country__${selectedStore().address.country}` as keyof typeof t
                        ]()}
                    </div>
                </address>
                <a
                    class="font-medium text-cta-blue no-underline"
                    href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
                        `${selectedStore().address?.street}, ${selectedStore().address?.postalCode} ${selectedStore().address?.city}`
                    )}`}
                    target="_blank"
                >
                    {t.sf__overlay__get_directions()}
                </a>
            </footer>

            {import.meta.env.VITE_INCLUDE_SWISSCOM === "true" && (
                <div class="flex flex-col gap-2 border-t border-divider-gray pt-3">
                    <Stock storeId={selectedStore().id} />
                </div>
            )}
        </div>
    );
}
