import {
    SearchBoxCore,
    SearchBoxOptions,
    SearchBoxRetrieveResponse,
    SearchBoxSuggestion,
    SearchBoxSuggestionResponse,
    SearchSession
} from "@mapbox/search-js-core";
import {
    ChainedTranslator,
    chainedTranslator,
    flatten,
    resolveTemplate,
    translator
} from "@solid-primitives/i18n";
import {
    createContext,
    createMemo,
    createSignal,
    ParentProps,
    useContext,
    Signal,
    Resource
} from "solid-js";
import { createStore, SetStoreFunction } from "solid-js/store";

import { buildStoreFinderOptions } from "./storeFinderOptions";
import {
    AvailableFilters,
    FilterParams,
    Format,
    Language,
    SidePanelContent,
    Store
} from "./types";
import * as de from "../l10n/locales/de/translations.json";
import * as en from "../l10n/locales/en-CH/translations.json";
import * as fr from "../l10n/locales/fr/translations.json";
import * as it from "../l10n/locales/it/translations.json";

type Translations = typeof en;
const dictionaries: Record<Language, Translations> = {
    [Language.De]: de,
    [Language.En]: en,
    [Language.Fr]: fr,
    [Language.It]: it
};

interface TranslationsContext {
    t: ChainedTranslator<Translations, string>;
    currentLanguage: Language;
    setLanguage: (l: Language) => void;
}

interface AppliedFilters {
    search: { text: string; resultCoordinates: mapboxgl.LngLatLike };
    formats: Format[];
    services: string[];
    openingHour: "all" | "openAwhile" | "open";
}

export interface AllStores {
    stores: Store[];
    appliedFilters: AppliedFilters;
    selectedStore: Store | undefined;
    sidePanelContent: "storeOverlay" | "filterControls" | undefined;
}

const TranslatorContext = createContext<TranslationsContext>();

export function useTranslator() {
    return useContext(TranslatorContext)!;
}

interface StoreMapContext {
    stores: Resource<Store[]>;
    availableFilters: Resource<AvailableFilters>;
    mapOptions: FilterParams;
    defaultBounds: mapboxgl.LngLatBounds;
    appliedFilterStore: [
        get: AppliedFilters,
        set: SetStoreFunction<AppliedFilters>
    ];
    sidePanelContentSignal: Signal<SidePanelContent>;
}

const StoreMapContext = createContext<StoreMapContext>();

export function useStoreMapContext() {
    return useContext(StoreMapContext)!;
}

interface SearchContext {
    searchSession: SearchSession<
        SearchBoxOptions,
        SearchBoxSuggestion,
        SearchBoxSuggestionResponse,
        SearchBoxRetrieveResponse
    >;
}

const SearchContext = createContext<SearchContext>();

export function useSearchContext() {
    return useContext(SearchContext)!;
}

export default function ContextProvider(
    props: ParentProps<{
        mapOptions: FilterParams;
        stores: Resource<Store[]>;
        availableFilters: Resource<AvailableFilters>;
        languageSignal: Signal<Language>;
    }>
) {
    // eslint-disable-next-line solid/reactivity
    const [language, setLanguage] = props.languageSignal;

    // eslint-disable-next-line solid/reactivity
    const storeFinderOptions = buildStoreFinderOptions(props.mapOptions);

    const dict = createMemo(() => flatten(dictionaries[language()]));
    const t: ChainedTranslator<Translations, string> = chainedTranslator(
        en,
        // eslint-disable-next-line solid/reactivity
        translator(dict, resolveTemplate)
    );

    const initialAppliedFilter: AppliedFilters = {
        search: {
            // eslint-disable-next-line solid/reactivity
            text: props.mapOptions.search || "",
            resultCoordinates: [0, 0]
        },
        formats: [],
        services: [],
        openingHour: "all"
    };

    // eslint-disable-next-line solid/reactivity
    const appliedFilterStore =
        createStore<AppliedFilters>(initialAppliedFilter);

    // eslint-disable-next-line solid/reactivity
    const sidePanelContentSignal = createSignal<SidePanelContent>(undefined);

    return (
        <TranslatorContext.Provider
            // eslint-disable-next-line solid/reactivity
            value={{
                t,
                setLanguage: setLanguage,
                currentLanguage: language()
            }}
        >
            <StoreMapContext.Provider
                value={{
                    // eslint-disable-next-line solid/reactivity
                    mapOptions: props.mapOptions,
                    // eslint-disable-next-line solid/reactivity
                    stores: props.stores,
                    // eslint-disable-next-line solid/reactivity
                    availableFilters: props.availableFilters,
                    defaultBounds: storeFinderOptions.boundingBox,
                    appliedFilterStore: appliedFilterStore,
                    sidePanelContentSignal: sidePanelContentSignal
                }}
            >
                <SearchContext.Provider
                    value={{
                        searchSession: new SearchSession(
                            new SearchBoxCore({
                                accessToken: import.meta.env
                                    .VITE_MAPBOX_ACCESS_TOKEN
                            }),
                            300
                        )
                    }}
                >
                    {props.children}
                </SearchContext.Provider>
            </StoreMapContext.Provider>
        </TranslatorContext.Provider>
    );
}
