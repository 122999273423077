/* @refresh reload */
import { createResource, createSignal } from "solid-js";
import { render } from "solid-js/web";

import "mapbox-gl/dist/mapbox-gl.css";
import "./index.css";

import { fetchAllStores, fetchAvailableFilters } from "./api";
import { Filters } from "./components/filters/filters";
import { SidePanel } from "./components/sidePanel";
import { StoreMap } from "./components/storeMap";
import ContextProvider from "./contextProviders";
import { FilterParams, Language } from "./types";
import { buildStoreFinderOptions } from "./utils";

function getInputRootAsHtmlElement(inputRoot: string | HTMLElement) {
    if (typeof inputRoot === "string") {
        return document.getElementById(inputRoot) as HTMLElement;
    } else {
        return inputRoot as HTMLElement;
    }
}

const storeFinderMap = (
    inputRoot: string | HTMLElement,
    filterParams?: FilterParams | URLSearchParams
) => {
    const { urlParams, parsedMapOptions } =
        buildStoreFinderOptions(filterParams);

    const root = getInputRootAsHtmlElement(inputRoot);

    let isInitialized = false;

    const allStoresPromise = fetchAllStores(urlParams);
    const availableFiltersPromise = fetchAvailableFilters(urlParams);

    const [stores] = createResource(() => allStoresPromise);
    const [availableFilters] = createResource(() => availableFiltersPromise);

    const [language, setLanguage] = createSignal<Language>(
        parsedMapOptions?.language || Language.De
    );

    new IntersectionObserver((entries) => {
        if (isInitialized || !entries[0].isIntersecting) {
            return;
        }

        isInitialized = true;
        render(
            () => (
                <ContextProvider
                    stores={stores}
                    availableFilters={availableFilters}
                    mapOptions={parsedMapOptions!}
                    languageSignal={[language, setLanguage]}
                >
                    <Filters />
                    <SidePanel />
                    <StoreMap />
                </ContextProvider>
            ),
            root
        );
    }).observe(root);

    return { setLanguage };
};

export { storeFinderMap };
